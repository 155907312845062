import { Link } from 'gatsby'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import { StaticImage } from 'gatsby-plugin-image'

import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

const Index = ({ data, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  const pic_hdg_top = '../assets/images/hdg/hdg_top_img.jpg'
  const pic_ddg_top = '../assets/images/ddg/ddg_top_img.jpg'
  const pic_edg_top = '../assets/images/edg/edg_top_img.jpg'

  return (
    <Layout>
      <Helmet title="cretia studio" />

      <Header />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <section id="latest" className="main special">
          <header className="major">
            <h2>Latest Products</h2>
          </header>
          <ul className="features">
            <li>
              <span className="image major"><Link to="/product/hope-detective-girl"><StaticImage src={pic_hdg_top} alt="" /></Link></span>
              <h3>希望探偵少女 (小説)</h3>
              <p>
              最悪の状況を打破し、探し求めていた少女を救うため、彼らは賢者倶楽部に立ち向かう。
              </p>
            </li>
            <li>
              <span className="image major"><Link to="/product/death-detective-girl"><StaticImage src={pic_ddg_top} alt="" /></Link></span>
              <h3>死神探偵少女</h3>
              <p>
              "死神の館"で行われる狂気のデスゲーム。証拠と推理で真相を暴き、失われた記憶を取り戻せ。
              </p>
            </li>
            <li>
            <span className="image major"><Link to="/product/escape-detective-girl"><StaticImage src={pic_edg_top} alt="" /></Link></span>
              <h3>脱出探偵少女</h3>
              <p>
              仕組まれた脱出ゲームと殺人事件。死と隣り合わせのデスゲームに"悲劇のヒロイン"は立ち向かう。
              </p>
            </li>
          </ul>
          <footer className="major">
            <ul className="actions">
              <li>
                <Link to="/product" className="button">
                  Learn More
                </Link>
              </li>
            </ul>
          </footer>
        </section>
      </div>
    </Layout>
  )
}

export default Index
